<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <!-- <TheSidebar/> -->
    <CWrapper>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <div class="alert">
              <CAlert
                :show.sync="alert.dismissCountDown"
                closeButton
                :color="alert.color"
              >
                {{ alert.message }}
                <CProgress
                  :color="alert.color"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  :size="'xs'"
                />
              </CAlert>
            </div>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
    <Loading v-show="loading"></Loading>
  </div>
</template>
  
  <script>
import TheAside from "./TheAside";
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

import Loading from "../components/loading/Modal.vue";

import { bus } from "../main";

export default {
  name: "TheContainer",
  components: {
    TheAside,
    TheSidebar,
    TheHeader,
    TheFooter,
    Loading,
  },
  data() {
    return {
      loading: false,

      alert: {
        dismissSecs: 3,
        dismissCountDown: 0,
        color: "info",
        message: "",
      },
    };
  },
  created() {
    bus.$on("loading", (data) => {
      this.loading = data;
    });
    bus.$on("alert", (item) => {
      this.alert.color = item.color;
      this.alert.dismissCountDown = this.alert.dismissSecs;
      this.alert.message = item.message;
    });
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showDismissibleAlerts() {
      ["alert1", "alert2"].forEach((alert) => (this[alert] = true));
    },
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/**  */
.alert {
  position: fixed;
  top: 100px;
  left: 1050px;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}
</style>
  